/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2022-12-30",
    versionChannel: "nightly",
    buildDate: "2022-12-30T00:18:10.336Z",
    commitHash: "89e01b55981efd22e5398de31d741d87dac8d75e",
};
